import type { Asset, AuthorData, BaseWidgetData } from '~/@types/cms';

export interface IWidgetProps<T> {
    data: T;
    componentIndex?: number;
}

export interface UrlLink {
    url?: string;
    label?: string | null;
    target?: string;
}

export const useWidget = <T extends BaseWidgetData>(props: IWidgetProps<T>) => {
    const getValueSequence = <TM extends BaseWidgetData>(value: WidgetList<TM>) => {
        if (!(value && value.sequence && value.components)) return [];
        return value.sequence.map((id) => value.components.find((c) => c.id === id)).filter(isDefined);
    };

    const resolveTag = (ref: any, refLookup: RefLookupFunction): string | undefined => {
        if (ref) {
            if (typeof ref === 'object') {
                throw new Error('not implemented yet - tag object');
            } else if (typeof ref === 'string') {
                const tagRef = refLookup<{ id: string; title: string }>(ref, 'tags');
                if (tagRef) {
                    return tagRef.title;
                }
            }
        }
        return undefined;
    };

    const resolveAuthor = (ref: any, refLookup: RefLookupFunction): AuthorData | undefined => {
        if (ref) {
            if (typeof ref === 'object') {
                throw new Error('author as object', ref);
            } else if (typeof ref === 'string') {
                const refData = refLookup<{ id: string; name: string; portrait: string }>(ref, 'authors');
                if (refData) {
                    return {
                        name: refData.name,
                        image: resolveAssetSrc(refData.portrait),
                    };
                }
            }
        }
        return undefined;
    };

    const resolveAssetSrc = (asset: Asset, bynderType: 'image' | '1to1' | '16to9' | '9to16' | '2to3' | '3to2' = 'image'): string | undefined => {
        if (typeof asset === 'string') return undefined;
        if (asset) {
            if (asset.id.indexOf('a:') === 0) {
                // kentico asset
                return asset.data?.src;
            } else if (asset.id.indexOf('b:') === 0) {
                // bynder asset
                return `${asset.data.baseUrl}${asset.data[bynderType] ? asset.data[bynderType] : asset.data.image}`;
            }
        }
        return undefined;
    };

    return {
        data: props.data,
        getValueSequence,
        resolveAssetSrc,
        resolveTag,
        resolveAuthor,
    };
};
